import axios from "axios"
import Vue from "vue"
import { Dialog, Toast } from "vant"
import * as ww from "@wecom/jssdk"
import utils from "@/utils/util"
import router from "@/router"

Vue.config.productionTip = false
// 不能设置为 true
axios.defaults.withCredentials = false
// 响应时间
axios.defaults.timeout = 60000
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded" // 配置请求头

if (process.env.NODE_ENV == "development") {
  axios.defaults.baseURL = "/api"
}
if (process.env.NODE_ENV == "production") {
  axios.defaults.baseURL = "/api"
}

// 请求之前
axios.interceptors.request.use(
  function(config) {
    // 添加token
    config.headers["authorization"] = localStorage.getItem("smcp_token")
      ? "bearer " + localStorage.getItem("smcp_token")
      : "bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIiLCJhdWQiOiIiLCJpYXQiOjE3MTgzNDk1ODksIm5iZiI6MTcxODM0OTU4OSwiZXhwIjoxNzE4NDM1OTg5LCJ1c2VyaWQiOiJIdWlzaGVuZzAxIn0.3P7Sjltg84VbsGMviA7bsnlUIrnee_Po0FSlBQs9VyA"
    return config
  },
  function(error) {
    // 请求错误
    return Promise.reject(error)
  }
)
// 响应的拦截器
axios.interceptors.response.use(
  (response) => {
    if (
      response.data &&
      response.data.code == 1 &&
      response.data.msg == "The token is expired."
    ) {
      localStorage.removeItem("smcp_token")
      localStorage.removeItem("userInfo")
      location.href = window.location.protocol + "//" + window.location.host
      return Promise.reject(
        response && response.data ? response.data : response || "Error"
      )
    } else if (response.data.code == 1) {
      Toast((response.data && response.data.msg) || "Error")
      return Promise.reject(
        response && response.data ? response.data : response || "Error"
      )
    } else if (response.data.code == -1000) {
      localStorage.clear()
      router.push({
        path: "/login"
      })
    } else if (response.data.code == -1001) {
      Toast.clear()
      return Dialog.alert({
        title: "提示",
        message: response.data.msg
      }).then(() => {
        localStorage.clear()
        if (utils.jupmBrowseType()) {
          ww.closeWindow()
        } else {
          router.push({
            path: "/noAccess"
          })
        }
      })
    } else {
      return response && response.data ? response.data : response
    }
  },
  (error) => {
    // 响应错误处理
    console.log("响应错误", error)
  }
)

const post = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    axios({
      url,
      data,
      method: "post"
    })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const upload = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data" // 配置请求头
    axios({
      url,
      data,
      method: "post"
    })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const get = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    axios({
      url,
      params: data,
      method: "get"
    })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export default {
  post,
  get,
  upload
}
