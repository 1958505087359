import { getConfig } from "@/api/index"
import * as ww from "@wecom/jssdk"

// 初始化sdk配置
const initWXsdk = function(url) {
  return new Promise((resolve, reject) => {
    getConfig({
      url: url
    }).then((res) => {
      const config = res.data.config
      const agentConfig = res.data.agentConfig
      ww.register({
        corpId: agentConfig.corpid, // 必填，当前用户企业所属企业ID
        agentId: agentConfig.agentid,
        jsApiList: ["getContext", "scanQRCode", "sendChatMessage"], // 必填，需要使用的JSAPI列表
        getConfigSignature, // 必填，根据url生成企业签名的回调函数
        getAgentConfigSignature, // 必填，根据url生成应用签名的回调函数
        onConfigSuccess(s1) {},
        onConfigFail(f1) {
          reject(f1)
        },
        onAgentConfigSuccess(s2) {
          resolve()
        },
        onAgentConfigFail(f2) {
          reject(f2)
        }
      })

      async function getConfigSignature(url) {
        // 根据 url 生成企业签名
        // 生成方法参考 https://developer.work.weixin.qq.com/document/path/90539
        return {
          timestamp: config.timestamp,
          nonceStr: config.nonceStr,
          signature: config.signature
        }
      }

      async function getAgentConfigSignature(url) {
        // 根据 url 生成应用签名，生成方法同上，但需要使用应用的 jsapi_ticket
        return {
          timestamp: agentConfig.timestamp,
          nonceStr: agentConfig.nonceStr,
          signature: agentConfig.signature
        }
      }
    })
  })
}
export default {
  initWXsdk
}
