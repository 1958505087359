import request from "@/utils/request"

// 登录
export function goLogin(data) {
  return request.post("login", data)
}

// 获取jssd签名
export function getConfig(data) {
  return request.post("getJsSign", data)
}

// 获取企业基础信息
export function getAppid() {
  return request.get("getCorp")
}

// 创建商品
export function getProduct(data) {
  return request.get("getProduct", data)
}

// 物流查询
export function searchLogistis(data) {
  return request.post("order/postQuery", data)
}
