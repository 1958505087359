import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import Vant from "vant"
import "vant/lib/index.css"
import request from "./utils/request"
import wechat from "./utils/wechat"
import util from "./utils/util.js"
import * as ww from "@wecom/jssdk"
import "swiper/css/swiper.min.css" // 注意这里的引入
import "@/styles/index.scss" // global css
import JsBarcode from "jsbarcode"

Vue.use(Vant)
Vue.config.productionTip = false
Vue.prototype.$util = util
Vue.prototype.$axios = request
Vue.prototype.$loading = util.loading
Vue.prototype.$wechat = wechat
Vue.prototype.$ww = ww
Vue.prototype.$barcode = JsBarcode
Vue.prototype.emptyImg = require("@/assets/imgs/empty_img.png")
for (const v in util) {
  if (!Vue.prototype[v]) Vue.prototype[v] = util[v]
}
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }

  if (to.name !== "login") {
    localStorage.setItem("firstUrl", to.path)
    localStorage.setItem("parameter", JSON.stringify(to.query))
    if (util.isToken()) {
      next("/wxLogin")
      return false
    }
  }
  next()
})

new Vue({
  router,
  render: (h) => h(App)
}).$mount("#app")
