import { Dialog, Toast } from "vant"

var SIGN_REGEXP = /([yMdhsm])(\1*)/g
var DEFAULT_PATTERN = "yyyy-MM-dd hh:mm"

function padding(s, len) {
  len = len - (s + "").length
  for (var i = 0; i < len; i++) {
    s = "0" + s
  }
  return s
}

export default {
  // 解析url
  parseQuery() {
    const res = {}
    const query = (location.href.split("?")[1] || "")
      .trim()
      .replace(/^(\?|#|&)/, "")

    if (!query) {
      return res
    }

    query.split("&").forEach((param) => {
      const parts = param.replace(/\+/g, " ").split("=")
      const key = decodeURIComponent(parts.shift())
      const val = parts.length > 0 ? decodeURIComponent(parts.join("=")) : null
      if (res[key] === undefined) {
        res[key] = val
      } else if (Array.isArray(res[key])) {
        res[key].push(val)
      } else {
        res[key] = [res[key], val]
      }
    })
    return res
  },

  // 时间格式 -> 年月日时分秒
  // 可传入 10位时间戳（秒） | 13位时间戳（毫秒） | 时间格式（object）
  formatDate(date, pattern) {
    if (typeof date == "string" || typeof date == "number") {
      date = new Date((date + "").length == 10 ? date * 1000 : date)
    }
    pattern = pattern || DEFAULT_PATTERN
    return pattern.replace(SIGN_REGEXP, function($0) {
      switch ($0.charAt(0)) {
        case "y":
          return padding(date.getFullYear(), $0.length)
        case "M":
          return padding(date.getMonth() + 1, $0.length)
        case "d":
          return padding(date.getDate(), $0.length)
        case "w":
          return date.getDay() + 1
        case "h":
          return padding(date.getHours(), $0.length)
        case "m":
          return padding(date.getMinutes(), $0.length)
        case "s":
          return padding(date.getSeconds(), $0.length)
      }
    })
  },
  // 验证金额
  validateMoney(val) {
    val = val.replace(/[^\d.]/g, "") // 保留数字
    val = val.replace(/^00/, "0.") // 开头不能有两个0
    val = val.replace(/^\./g, "0.") // 开头为小数点转换为0.
    val = val.replace(/\.{2,}/g, ".") // 两个以上的小数点转换成一个
    val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); // 只保留一个小数点
    /^0\d+/.test(val) ? (val = val.slice(1)) : ""; // 两位以上数字开头不能为0
    /\.\d{3}/.test(val) ? (val = val.slice(0, -1)) : "" // 限制最多两位小数
    return val
  },
  // 加载中
  loading(text) {
    Toast.loading({
      message: text || "加载中...",
      forbidClick: true,
      duration: 0
    })
  },

  // 确认弹框
  confirmDialog(title, content, confirmFn, cancelFn) {
    Dialog.confirm({
      title: title,
      message: content
    })
      .then(() => {
        confirmFn && confirmFn()
      })
      .catch(() => {
        cancelFn && cancelFn()
      })
  },
  // 判断设备类型
  jupmBrowseType() {
    const isMobile = window.navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    ) // 判断移动端类型
    const isWeCom = /wxwork/i.test(navigator.userAgent) // 判断是否为企业微信类

    if (isWeCom && isMobile) {
      // 判断是否是手机端企业微信
      return true
    }
    if (isWeCom && !isMobile) {
      // 判断是否是PC端企业微信
      return false
    }
  },
  // 判断token
  isToken: function() {
    var now = parseInt(new Date().getTime() / 1000)
    var token = localStorage.getItem("smcp_token")
    var over = localStorage.getItem("smcp_exp")
    // || now > over
    if (!token || now > over) {
      return true
    } else {
      return false
    }
  }
}
